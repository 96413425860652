import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

type Props = {
  description?: string;
  lang?: string;
  meta?: JSX.IntrinsicElements['meta'][];
  title: string;
  image?: string;
  siteUrl?: string;
};

const SEO: React.FC<Props> = ({
  description,
  lang,
  meta,
  title,
  image,
  siteUrl,
}) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          image
          siteUrl
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;
  const metaImage =
    (siteUrl || site.siteMetadata.siteUrl) + (image || site.siteMetadata.image);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          property: 'image',
          content: metaImage,
        },
        {
          property: 'og:title',
          content: title,
        },
      ]}
    />
  );
};

SEO.defaultProps = {
  lang: 'CZ',
  meta: [],
  description: '',
};

export default SEO;
